import { qualityTelegramSituation } from "@/apis/home.js";
import { getTags } from "@/views/home/store.js";

let $ = val => document.querySelector(val);

let $$ = val => [...document.querySelectorAll(val)];

export default {
  name: "report",
  computed: {
    dates() {
      return this.data.map(one => one.date);
    }

  },
  methods: {
    getTags,

    toItem(i) {
      this.curActive = i;
      let dates = $$(".report-list .one-day");
      window.scrollTo({
        top: dates[i].offsetTop,
        behavior: "smooth"
      });
    },

    async getList() {
      this.isReady = false;
      let {
        totalQualityTelegramSortList
      } = await qualityTelegramSituation();
      console.log(totalQualityTelegramSortList);
      let res = [];
      totalQualityTelegramSortList.forEach(one => {
        let {
          pubTime,
          title,
          sourceUrl,
          genEnterpriseList
        } = one;
        let [date, time] = pubTime.split("T");
        let target = res.find(one => one.date === date);

        if (!target) {
          target = {
            date,
            listInfo: []
          };
          res.push(target);
        }

        target.listInfo.push({ ...one,
          time,
          title,
          url: sourceUrl,
          companyList: genEnterpriseList || []
        });
      });
      this.data = res;
      this.isReady = true;
      this.addScroll();
      this.$nextTick(() => {
        this.addScroll(); //   console.log(this.data)
      });
    },

    addScroll() {
      let $ = val => document.querySelector(val);

      let $$ = val => [...document.querySelectorAll(val)];

      let dates = $$(".report-list h1");
      let arr = dates.map(one => one.offsetTop);
      let preActive = 0;
      window.addEventListener("scroll", e => {
        let top = document.scrollingElement.scrollTop;
        let index = arr.findIndex((one, i) => {
          let isLast = arr.length - 1 === i;
          return isLast ? top >= one : top >= one && arr[i + 1] > top;
        });

        if (index !== preActive) {
          preActive = index;
          this.curActive = index;
        }
      });
    }

  },

  mounted() {
    this.getList();
  },

  data() {
    return {
      isReady: false,
      curActive: 0,
      data: []
    };
  }

};